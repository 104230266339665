import { useEffect, useState } from 'react';
import GraphqlService from '../service/graphqlService';

const usePollingGetIotMessages = ({
  project,
  serialNumber,
  topicNumber,
}) => {
  const [iotMessages, setIotMessages] = useState([]);
  const [polling, setPolling] = useState(false);

  useEffect(() => {
    let interval;

    const fetchMessages = async () => {
      try {
        const result = await GraphqlService.getIotMessages({
          projectId: project.code,
          serialNumber,
          topicNumber,
        });

        if (result) {
          const parsedResult = JSON.parse(result);
          setIotMessages(parsedResult);

          // Stop polling if a response is received
          if (parsedResult.length > 0) {
            setPolling(false);
            clearInterval(interval);
          }
        }
      } catch (error) {
        console.error('Error fetching IoT messages:', error);
      }
    };

    if (polling) {
      interval = setInterval(() => {
        fetchMessages();
      }, 1000); // Poll every second
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [polling, project, serialNumber, topicNumber]);

  const startPolling = () => setPolling(true);
  const stopPolling = () => {
    setPolling(false);
    setIotMessages([]);
  };

  return {
    iotMessages,
    startPolling,
    stopPolling,
  };
};

export default usePollingGetIotMessages;
