export const AppSyncAPIKey = 'da2-rmajnjlshve2zohgsbnfmavcsa';
export const UserPoolId = 'eu-west-1_Y7gEBL6oW';
export const UserPoolClientId = 'kmr5lgkr9825671j3j2s3h47r';
export const GraphQLAPIURL = 'https://wdh2rtwa2jdd5pphdllxlztwse.appsync-api.eu-west-1.amazonaws.com/graphql';
export const IdentityPoolId = 'eu-west-1:66b182bc-7c5a-4462-9b13-3cf77e1638c1';
export const target = 'DEV';
export const region = 'eu-west-1';

// eslint-disable-next-line import/no-dynamic-require
const env = require(`./env.${target}`);

export const config = {
  aws_project_region: region,
  aws_user_pools_id: UserPoolId,
  aws_user_pools_web_client_id: UserPoolClientId,
  aws_appsync_graphqlEndpoint: GraphQLAPIURL,
  aws_appsync_apiKey: AppSyncAPIKey,
  aws_appsync_authenticationType: 'AWS_LAMBDA',
  Auth: {
    identityPoolId: IdentityPoolId,
    region,
    userPoolId: UserPoolId,
    userPoolWebClientId: UserPoolClientId,
  },
  Storage: {
    AWSS3: {
      bucket: `alm-graphql-files-bucket-${target.toLowerCase()}-${region}`,
      region,
    },
  },
  oauth: {
    domain: env.authUri,
    redirectSignIn: env.redirectUri,
    redirectSignOut: env.redirectUri,
    responseType: 'token',
    scope: ['openid'],
  },
  env,
};

export default config;
